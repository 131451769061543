<template>
  <div class="home">
    <div class="middle">
      <div class="overlay">
        <div class="datetime">{{ datetime }}</div>
        <div class="fakecontrol2">
          <img src="@/assets/geluiduit.png" alt="">
          <img src="@/assets/vergroot.png" alt="">
        </div>
        <div class="live">
          <img src="../assets/live.gif" alt=""></div>
        <div class="container">
          <img class="heading" alt="" src="../assets/sneeuwthetalSVG.svg"
               v-bind:class="{ active: isActive }"
          ><br/>
          <div
            class="btn"
            v-if="!alreadyChecked"
            @click="checkSnow"
            v-bind:class="{ active: isActive }"
          >
          </div>
          <div
            class="btn_again"
            v-if="alreadyChecked"
            @click="checkSnow"
            v-bind:class="{ active: isActive }"
          >
          </div>
        </div>
      </div>
      <video
        ref="video"
        @ended="videoEnded"
        :src="require(`@/assets/video/${activeVideo}`)"
        :loop="loopIt"
        :muted="muteIt"
        autoplay
        playsinline=""
      >
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      alreadyChecked: false,
      btnHover: false,
      daytime: 'day',
      muteIt: true,
      loopIt: true,
      isActive: false,
      activeVideo: 'default_day.mp4',
      defaultVideo: 'default_day.mp4',
      videoLoc: '/assets/video/',
      videos: {
        day: [
          'day_aniek_1.mp4',
          'day_aniek_2.mp4',
          'day_dave_1.mp4',
          'day_janine_1.mp4',
          'day_emily_2.mp4',
          'day_paul_3.mp4',
          'day_janine_2.mp4',
          'day_janine_3.mp4',
          'day_nick_3.mp4',
          'day_paul_1.mp4',
          'day_paul_2.mp4',
          'day_dave_2.mp4',
          'day_dave_3.mp4',
          'day_emily_1.mp4',
          'day_jorg_1.mp4',
          'day_sjim_3.mp4',
          'day_janhein_1.mp4',
          'day_janhein_2.mp4',
          'day_vince_1.mp4',
          'day_vince_2.mp4',
          'day_ruben_1.mp4',
          'day_ruben_4.mp4',
          'day_sjim_1.mp4',
          'day_sjim_2.mp4',
          'day_marco_2.mp4',
          'day_marco_3.mp4',
          'day_nick_1.mp4',
          'day_vince_3.mp4',
          'day_marco_1.mp4',
          'day_ruben_2.mp4',
          'day_ruben_3.mp4',
          'day_nick_2.mp4',
          'day_jorg_2.mp4',
          'day_lotte_1.mp4',
          'day_lotte_2.mp4',
          'day_lotte_3.mp4',
        ],
        night: [
          'night_emily_1.mp4',
          'night_janhein_1.mp4',
          'night_janine_1.mp4',
          'night_janine_2.mp4',
          'night_janine_3.mp4',
          'night_jorg_1.mp4',
          'night_jorg_2.mp4',
          'night_nick_1.mp4',
          'night_nick_2.mp4',
          'night_paul_1.mp4',
          'night_paul_2.mp4',
          'night_sjim_1.mp4',
          'night_vince_1.mp4',
          'night_vince_2.mp4',
        ],
      },
      datetime: 'Maandag 2 december 2019 - 14:40',
    };
  },
  computed: {
    videoElement() {
      return this.$refs.video;
    },
    btnElement() {
      return this.$refs.btn;
    },
  },
  created() {
    if (new Date().getHours() >= 16 || new Date().getHours() <= 8) {
      this.daytime = 'night';
    } else {
      this.daytime = 'day';
    }
    this.defaultVideo = 'default_' + this.daytime + '.mp4';
    this.activeVideo = this.defaultVideo;
    this.$refs.video.load();
    this.$refs.video.play();
  },
  mounted() {
    this.startTime();
  },
  methods: {
    getRand() {
      if (new Date().getHours() >= 16 || new Date().getHours() <= 8) {
        this.daytime = 'night';
      } else {
        this.daytime = 'day';
      }
      return Math.floor(Math.random() * Math.floor(this.videos[this.daytime].length));
    },
    checkSnow() {
      if (new Date().getHours() >= 16 || new Date().getHours() <= 8) {
        this.daytime = 'night';
      } else {
        this.daytime = 'day';
      }
      this.loopIt = false;
      this.muteIt = false;
      const randomNumber = this.getRand();
      this.activeVideo = this.videos[this.daytime][randomNumber];
      this.$ga.event('videoPlay', 'checkSnow', this.videos[this.daytime][randomNumber], randomNumber);
      this.videoElement.load();
      this.videoElement.play();
      this.isActive = true;
      this.alreadyChecked = true;
    },
    videoEnded() {
      this.activeVideo = this.defaultVideo;
      this.loopIt = true;
      this.muteIt = true;
      this.videoElement.load();
      this.videoElement.play();
      this.isActive = false;
    },
    startTime() {
      const today = new Date();
      const date = this.checkTime(today.getDate()) + '/' + this.checkTime(Math.round(today.getMonth() + 1)) + '/' + today.getFullYear();
      const h = today.getHours();
      let m = today.getMinutes();
      let s = today.getSeconds();
      m = this.checkTime(m);
      s = this.checkTime(s);
      this.datetime = date + ' ' + h + ':' + m + ':' + s;
      setTimeout(this.startTime, 500);
    },
    checkTime(i) {
      if (i < 10) {
        i = '0' + i;
      } // add zero in front of numbers < 10
      return i;
    },
  },
};
</script>

<style lang="scss">
video {
  width: 90%;
  @media screen and (max-width: 800px) {
    width: 140%;
    height: 140%;
    margin: 0 0 0 -20%;
  }
}

.middle {
  overflow: hidden;
  position: relative;
  background: #000;
}

.about {
  color: #000;
  padding: 16px;
}

.overlay {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .live {
    position: absolute;
    top: 20px;
    right: 6%;
    @media screen and (max-width: 800px) {
      right: 3%;
    }

    img {
      width: 120px;
      @media screen and (max-width: 1000px) {
        width: 80px;
      }
      @media screen and (max-width: 600px) {
        width: 50px;
      }
    }
  }

  .datetime {
    position: absolute;
    bottom: 8%;
    left: 6%;
    color: #FFF;
    font-weight: bold;
    @media screen and (max-width: 1000px) {
      left: 3%;
      font-size: 12px;
    }
    @media screen and (max-width: 600px) {
      left: 3%;
      font-size: 10px;
    }
  }

  .fakecontrol2 {
    position: absolute;
    right: 6%;
    bottom: 8%;
    color: #000;
    font-weight: bold;
    opacity: 0.4;

    img {
      height: 75px;
      position: relative;
      top: 30px;
      left: 25px;
      @media screen and (max-width: 1000px) {
        height: 50px;
        top: 15px;
        left: 10px;
      }
      @media screen and (max-width: 600px) {
        height: 40px;
        top: 15px;
        left: 10px;
      }
    }
  }

  .container {
    width: 100%;
  }

  .heading {
    width: 60%;
    transition: all 3s ease;

    &.active {
      opacity: 0.2;
    }
  }

  .btn, .btn_again {
    width: 40%;
    height: 150px;
    max-width: 800px;
    background: url('../assets/check-het.png') no-repeat 50% 50%;
    background-size: 100% auto;
    display: inline-block;
    margin: 20px 0 0 0;
    cursor: pointer;
    transition: all 3s ease;
    @media screen and (max-width: 600px) {
      height: 40px;
    }

    &:hover {
      background: url('../assets/check-het_active.png') no-repeat 50% 50%;
      background-size: 100% auto;
    }

    &.active {
      opacity: 0;
    }
  }

  .btn_again {
    background: url('../assets/check-het-opnieuw.png') no-repeat 50% 50%;
    background-size: 100% auto;

    &:hover {
      background: url('../assets/check-het-opnieuw_active.png') no-repeat 50% 50%;
      background-size: 100% auto;
    }
  }
}
</style>
