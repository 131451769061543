<template>
  <div id="app">
    <div class="top">
      <img class="logo" alt="Sneeuwt het al?" src="@/assets/logo.jpg">
      <div id="nav">
        <router-link to="/" @click="activateHome()">
          <img
            v-if="currentPage!=='/'"
            src="@/assets/sneeuwthetal_btn.png"
          />
          <img
            v-if="currentPage==='/'"
            src="@/assets/sneeuwthetal_btn_active.png"
          />
        </router-link> |
        <router-link to="/about" @click="activateAbout()">
          <img
            v-if="currentPage!=='/about'"
            src="@/assets/over_btn.png"
          />
          <img
            v-if="currentPage==='/about'"
            src="@/assets/over_btn_active.png"
          />
        </router-link>
      </div>
    </div>
    <router-view/>

  </div>
</template>

<script>
export default {
  data() {
    return {
      activePage: 'home',
    };
  },
  computed: {
    currentPage() {
      console.log(this.$route.path);
      return this.$route.path;
    },
  },
  methods: {
    activateHome() {
      this.activePage = 'home';
      console.log('activate home');
    },
    activateAbout() {
      this.activePage = 'about';
      console.log('activate about');
    },
  },
};
</script>

<style lang="scss">
html, body { margin: 0; padding:0; height: 100%; width: 100%; background: #f8cd25;}
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #f9cd39;
}

.top {
  width: 90%;
  text-align: left;
  margin: 0 0 0 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    display: block;
    text-align: center;
  }
}
.logo { margin: 20px 0; width: 250px; }

#nav {
  text-align: right;
  @media screen and (max-width: 600px) {
    text-align: center;
  }
  a {
    font-weight: bold;
    color: #FFF;
    img { height: 90px;
      @media screen and (max-width: 800px) {
          height: 50px;
      }
    }

    &.router-link-exact-active {
      color: #000;
    }
  }
}
</style>
